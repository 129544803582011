<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    import settings from "@/backend/LocalSettings";

    export default {
        name: 'App',
        methods:
            {
                async check_version()
                {
                    var local_version  = settings.version;
                    var server_version = await this.post("version");
                    if( local_version != server_version )
                    {
                        this.$notify({
                            title: 'Atentie',
                            message: 'A aparut o noua versiune - <button onclick="window.location.reload()">Upgrade</button> ',
                            dangerouslyUseHTMLString: true,
                            type: 'warning',
                            duration: 0
                        });
                    }
                },
                post: async function(url, args={}){
                    var response = await this.$http.post(url, args );
                    return response.bodyText;
                },
            },
        mounted()
        {
            //verificam daca exista o versiune mai noua
            this.check_version();
        }
    }
</script>

<style lang='less'>

    @albastru-deschis: #44b0cc;

    .el-form-item__label{
        display: block !important;
        float: none !important;
        text-align: left !important;
    }

    .text-albastru{
        color: @albastru-deschis;
    }

    .bg-albastru{
        background-color: @albastru-deschis;
    }

    div.titlu{
        background-color: #0d7aa3;
        padding: 0;
        margin:0;
        h1
        {
            line-height: 60px;
            color: white;
            padding: 0px 15px;
            font-family: 'Lato', sans-serif;
            font-weight: 300;
            margin:0;
        }
        .btn-adauga{
            float: right;
            height: 60px;
            padding-left: 50px;
            padding-right: 50px;
            border: none;
            border-radius: 0%;
        }
    }


    html {
        height: 100%;
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    body{
        background-color: #f7f7f8 !important;
    }
    .my-dialog-class .el-dialog{
        width: 50%;
    }
    .full-width{
        width: 100%;
    }

    .el-header{
        padding:0 !important;
        .navbar{
            margin-top: 0;
            box-shadow: fade(black,20%) 1px 1px, fade(black,19%) 0 3px 5px;
        }
        .bg-dark{
            background-color: hsla(0,0%,100%,.9)!important;   
            
        }
        .nav-link{
            color: #4d5c60 !important;
            text-transform: uppercase;
            font-size: 13px;
        }
        .navbar-dark .active .nav-link{
            background-color: rgba(77,92,96,.126);
            border-radius: 10px;
            color: #4d5c60!important;
        }
    }
    .el-container{
        padding: 3px !important;
    }

</style>
